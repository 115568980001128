import React from 'react';
import Main from '../../components/pages/Main';
import SwitchAccountant from '../../components/pages/SwitchAccountant';

const TrocarContador = () => (
  <Main page="trocar-contador-wdec">
    <SwitchAccountant />
  </Main>
);

export default TrocarContador;
